@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #e9f4e7;
}

.footerImge {
  background-image: url("/public/footer.png");
  object-fit: cover;
}
